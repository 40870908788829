import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Onboarding from "../../assets/images/Onboarding.png";
import OnboardingUser from "../../assets/images/onboardingUser.png";

import { ReactComponent as ArrowRight } from "../../assets/icons/arrowRight.svg";
import "./OnboardingScreen.scss";

import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store/store";
import {
  getTalentOnboardingQuestions,
  postTalentOnboardingQuestions,
} from "../../redux/TalentSlice/TalentSlice";
import Cookies from "js-cookie";
import {
  getEmployerOnboardingQuestions,
  postEmployerOnboardingQuestions,
} from "../../redux/EmployerSlice/EmployerSlice";
import {
  Explorer,
  Strategist,
  TechEnthusiast,
  Trailblazer,
} from "../../assets/images";

import { updateBoardingCompletion } from "../../redux/ProfileSlice/ProfileSlice";
import {
  BasicInclusivePractices,
  DevelopingInclusivePractices,
  ExemplaryInclusivePractices,
  OpportunityForImprovement,
} from "../../assets/icons";
import { TailSpin } from "react-loader-spinner";
import Slider from "@mui/material/Slider";

const avatarImages: any = {
  "The Strategist": <Strategist />,
  "The Explorer": <Explorer />,
  "The Seeker": <TechEnthusiast />,
  "The Trailblazer": <Trailblazer />,
  "Exemplary Inclusive Practices": <ExemplaryInclusivePractices />,
  "Developing Inclusive Practices": <DevelopingInclusivePractices />,
  "Basic Inclusive Practices": <BasicInclusivePractices />,
  "Oppurtunity for Improvement": <OpportunityForImprovement />,
};

const OnboardingScreen = () => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [questions, setQuestions] = useState<any>([]);
  const [answers, setAnswers] = useState<any>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<any>({});
  const [sliderValues, setSliderValues] = useState<any>({});
  const [result, setResult] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const btnOptionStyles = {
    borderRadius: "12px",
    width: "100%",
    padding: "16px 10px",
  };

  const profileInfo = useSelector((state: any) => state.profile.profileInfo);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const role = Cookies.get("role");

  useEffect(() => {
    const fetchQuestions = (action: any) => {
      dispatch(action).then((res: any) => {
        if (res.type.includes("rejected")) navigate("/login");
        else {
          // Map input_type values to corresponding input types
          const questionsWithInputTypes = res.payload.map((question: any) => {
            const questionData = question.question[0]; // Extract the first element from the question array
            return {
              question: questionData.question,
              id: questionData.id,
              inputType:
                questionData.input_type === "single_choice"
                  ? "single-choice"
                  : questionData.input_type === "multiple_choice"
                  ? "multiple-choice"
                  : questionData.input_type === "slider"
                  ? "slider"
                  : null,
              answers: question.answers,
              slider_value: questionData.slider_value,
            };
          });
          setQuestions(questionsWithInputTypes);
        }
      });
    };

    
    switch (role) {
      case "talent":
        fetchQuestions(getTalentOnboardingQuestions());
        break;
      case "employer":
        fetchQuestions(getEmployerOnboardingQuestions());
        break;
      default:
        break;
    }
  }, [dispatch, navigate, role]);

  useEffect(() => {
    if (profileInfo?.on_boarding_completed) {
      dispatch(updateBoardingCompletion());
      Cookies.set("boarding-completed", "true");
      navigate("/dashboard");
    }
  }, [profileInfo, dispatch, navigate]);

  const handleAnswerSelection = (questionId: number, answerId: number) => {
    setSelectedAnswers((prevSelectedAnswers: any) => {
      const currentAnswers = prevSelectedAnswers[questionId] || [];

      const question = questions.find((q: any) => q.id === questionId);

      if (question.inputType === "multiple-choice") {
        // Handle multiple answers
        if (currentAnswers.includes(answerId)) {
          console.log("Answer already selected, removing it");
          return {
            ...prevSelectedAnswers,
            [questionId]: currentAnswers.filter((id: number) => id !== answerId),
          };
        } else {
          console.log("Answer not selected, adding it");
          return {
            ...prevSelectedAnswers,
            [questionId]: [...currentAnswers, answerId],
          };
        }
        } else if (question.inputType === "single-choice") {
        // Handle single answer and move to next question
          const newSelectedAnswers = {
            ...prevSelectedAnswers,
            [questionId]: [answerId],
          };
          setSelectedAnswers(newSelectedAnswers);
          handleAnswerSubmission(questionId, newSelectedAnswers);
          console.log("Selected answers:", newSelectedAnswers);
          return newSelectedAnswers;
      }
    })
  }
    ;

  const handleSliderChange = (questionId: number, value: number) => {
    console.log("questionId", questionId);
    console.log("value", value);
    setSliderValues((prevSliderValues: any) => ({
      ...prevSliderValues,
      [questionId]: value,
    }));
  };
  interface Answer {
    question: number;
    answers: number[];
    user: any;
    slider_value: any;
  }
  
const handleAnswerSubmission = (questionId: number, updatedSelectedAnswers?: any) => {
    console.log("Starting submission for questionId:", questionId);
    const selectedAnswerIds = (updatedSelectedAnswers || selectedAnswers)[questionId] || [];
    console.log("Selected answer IDs:", selectedAnswerIds);
    const sliderValue = sliderValues[questionId];
    const question = questions.find((q: any) => q.id === questionId);
  
    if (!question) {
        console.log("No question found");
        return;
    }
  
    let newAnswers: Answer[] = [];
  
    if (question.inputType === "multiple-choice") {
        if (selectedAnswerIds.length === 0) {
            console.log("No answers selected for multiple choice");
            return;
        }
        newAnswers = selectedAnswerIds.map((answerId: number) => ({
            question: questionId,
            answers: answerId,
            user: profileInfo.pk,
            slider_value: null
        }));
    } else if (question.inputType === "slider") {
        if (sliderValue === undefined) {
          console.log("No slider value selected");
          return;
        }
        newAnswers = [
            {
                question: questionId,
                answers: [],
                user: profileInfo.pk,
                slider_value: sliderValue,
            },
        ];
    } else if (question.inputType === "single-choice") {
        if (selectedAnswerIds.length !== 1) return;
        newAnswers = [
            {
                question: questionId,
                answers: selectedAnswerIds[0],
                user: profileInfo.pk,
                slider_value: null
            },
        ];
    }

    console.log("New answers to be added:", newAnswers);
    
    // Immediately update the answers state
    setAnswers((prevAnswers: any[]) => {
        const updatedAnswers = [...prevAnswers, ...newAnswers];
        console.log("Updated answers array:", updatedAnswers);
        
        // Clear selections for next question
        setSelectedAnswers({});
        setSliderValues((prev: any) => {
            const newValues = { ...prev };
            delete newValues[questionId];
            return newValues;
        });

        // Check if this was the last question
        if (updatedAnswers.length === questions.length) {
            console.log("This was the last question, submitting all answers");
            setLoading(true);
            dispatch(updateBoardingCompletion());
            Cookies.set("boarding-completed", "true");

            const submitAction = role === "talent" 
                ? postTalentOnboardingQuestions(updatedAnswers)
                : postEmployerOnboardingQuestions(updatedAnswers);
            
                console.log("Dispatching submit action:", submitAction);

                dispatch(submitAction).then((res) => {
                  console.log("Submit action response:", res);
                  if (res.payload?.avatar) {
                    setResult(res.payload);
                    setActiveStep(3);
                  } else {
                    console.log("No avatar found in response");
                  }
                }).catch((error) => {
                  console.error("Error in submit action:", error);
                }).finally(() => {
                  setLoading(false);
                });
              }
              console.log("Updated answers:", updatedAnswers);
              return updatedAnswers;
    });
};
const formatCurrency = (value: number) => {
  return `$${value.toLocaleString()}`;
};

const renderQuestionOptions = () => {
  const currentQuestion = questions[answers.length];
  console.log("Current Question:", currentQuestion); // Debugging statement
  if (!currentQuestion) return null;

  if (currentQuestion.inputType === "multiple-choice") {
    return currentQuestion.answers.map((data: any) => (
      <div
        key={data.id}
        onClick={() => handleAnswerSelection(currentQuestion.id, data.id)}
        className={selectedAnswers[currentQuestion.id]?.includes(data.id) ? 'selected' : ''}
      >
        <Button type={"INVERTED"} style={btnOptionStyles}>
          {data.answer}
        </Button>
    </div>
    ));
  } else if (currentQuestion.inputType === "slider") {
    const min = 20000;
    const max = 300000;
    const currentValue = sliderValues[currentQuestion.id] || currentQuestion.slider_value || min;
    console.log("Slider Value:", currentValue); // Debugging statement

    return (
      <div className="slider-container">
        <div className="slider-labels">
          <span>{formatCurrency(min)}</span>
          <span>{formatCurrency(max)}</span>
        </div>
        <div className="slider-value">{formatCurrency(currentValue)}</div>
        <Slider
          value={currentValue}
          onChange={(e, value) => handleSliderChange(currentQuestion.id, value as number)}
          aria-labelledby="continuous-slider"
          step={10000}
          min={min}
          max={max}
        />
      </div>
    );
  } else if (currentQuestion.inputType === "single-choice") {
    return currentQuestion.answers.map((data: any) => (
      <div
        key={data.id}
        onClick={() => handleAnswerSelection(currentQuestion.id, data.id)}
        className={selectedAnswers[currentQuestion.id]?.includes(data.id) ? 'selected' : ''}
      >
        <Button type={"INVERTED"} style={btnOptionStyles}>
          {data.answer}
        </Button>
      </div>
    ));
  }
};

  const getPercentageCompletion = () => {
    return (answers.length / questions.length) * 100;
  };

  return (
    <div className="onboarding-container">
      <NavBar
        hide={true}
        back={activeStep === 2 ? true : false}
        disabled={answers.length === 0 || loading}
        disableNavigation={true}
        backAction={() => {
          if (activeStep === 2) {
            setAnswers(answers.slice(0, -1));
          }
        }}
      />
      <div className="onboarding">
        {/*Shannon's landing screen*/}
        {activeStep === 1 && (
          <div className="onboarding-section">
            <img src={Onboarding} height={670} width={506} alt="Onboarding" />
            <div className="onboarding-details-container">
              <div className="onboarding-description-container">
                <div className="onboarding-description">
                  ”As an Afro-Latina and single mother, I struggled to make ends
                  meet. I had a obtained my bachelors degree and worked a
                  corporate job but it wasn’t enough.
                </div>
                <div className="onboarding-description">
                  I faced barriers and bias trying to climb the ladder and so I
                  set out to make a career change. Transitioning into the tech
                  industry changed my life and now I help others to do the
                  same.”
                </div>
              </div>

              <div className="sector-details-container">
                <div className="sector-details">
                  <div className="salary-in-dollars">$67k</div>
                  <div className="designation-salary">Non-tech salary</div>
                </div>

                <div className="sector-details">
                  <div className="salary-in-dollars">$115k</div>
                  <div className="designation-salary">Tech salary</div>
                </div>
              </div>

              <Link
                onClick={() => setActiveStep(2)}
                className="buttonWithArrow"
                to={"#"}
              >
                Get started for free
                <ArrowRight />
              </Link>
            </div>
          </div>
        )}

        {/*Questionaire*/}
        {activeStep === 2 && (
          <div className="talent-question-container">
            <div className="talent-question-section">
              <div className="progressive-bar-width">
                <div className="w3-light-grey w3-round-xlarge">
                  <div
                    style={{
                      width: `${getPercentageCompletion()}%`,
                    }}
                    className="w3-container w3-blue w3-round-xlarge"
                  />
                </div>
              </div>

              {/* Sabrina Profile Information */}
              <div className="profile-info-container">
                <div className="profile-picture-info-container">
                  <div className="profile-container">
                    <img
                      src={OnboardingUser}
                      alt="Onboarding user"
                      className="profile-picture"
                    />
                  </div>
                  <div className="profile-username">Sabrina</div>
                </div>

                <div className="info-container">
                  <div className="info-description">
                    {!answers.length &&
                      "Welcome! My name is Sabrina and I will be your career guide. "}
                    {questions[answers.length]?.question}
                  </div>
                </div>
              </div>

              {/* Button Options */}
              <div className="btn-options">
                {!loading ? (
                  renderQuestionOptions() // Call the function here
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <TailSpin
                      height="160"
                      width="160"
                      color="#FBAD84"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{
                        justifySelf: "center",
                        alignSelf: "center",
                        display: "flex",
                      }}
                      wrapperClass="onboarding-spinner"
                      visible={true}
                    />
                  </div>
                )}
              </div>

              {(questions[answers.length]?.inputType === "multiple-choice" || questions[answers.length]?.inputType === "slider") && (
              <div>
                <div className="submit-btn">
                  <Button
                    onClick={() => {
                      console.log("Submit button clicked");
                      handleAnswerSubmission(questions[answers.length]?.id);
                    }}
                    type={"PRIMARY"}
                    style={btnOptionStyles}
                  >
                    Submit Answers
                  </Button>
                </div>
              </div>
            )}
              

            </div>
          </div>
        )}

        {/*Avatar Assignment*/}
        {activeStep === 3 && (
          <div className="talent-question-container">
            <div className="progressive-bar-width">
              <div className="w3-light-grey">
                <div className="w3-blue explorer-mainbar-percentage" />
              </div>
            </div>

            {/* <Explorer className="explorer-img-margin"  /> */}
            {avatarImages[result?.avatar ?? 0]}

            <div className="the-explorer">
              {result?.avatar ?? "Employer onboarding WIP"}
            </div>

            <div className="profile-info-container">
              <div className="profile-picture-info-container">
                <div className="profile-container">
                  <img
                    src={OnboardingUser}
                    alt="Onboarding user"
                    className="profile-picture"
                  />
                </div>
                <div className="profile-username">Sabrina</div>
              </div>

              <div className="info-container">
                <div className="info-section">
                  {/* <div className="info-header">Best Course of Action:</div> */}
                  <div className="info-description">
                    {result?.recommendation}
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={() => navigate("/dashboard")}
              className="dashboard-btn"
            >
              <Button>Go to Dashboard</Button>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default OnboardingScreen;