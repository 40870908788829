import React from "react";
import { FC, ReactNode, CSSProperties } from "react";
import "./Button.scss";

interface ButtonProps {
  children: React.ReactNode;
  type?: "INVERTED" | "PRIMARY";
  style?: React.CSSProperties;
  className?: string;
  backgoundColor?: string;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({
  children,
  type = "PRIMARY",
  style,
  className,
  backgoundColor,
  onClick
}) => {
  const updatedStyle: CSSProperties = { ...style };

  if (backgoundColor) {
    updatedStyle.backgroundColor = backgoundColor;
  }
  return (
    <button
      className={`${type === "PRIMARY" ? "btn-primary" : "btn-inverted"} ${
        className ? className : ""
      }`}
      style={updatedStyle}
      onClick={() => {
        console.log("Button component clicked");
        if (onClick) onClick();
      }}
    >
      {children}
    </button>
  );
};

export default Button;
